import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Article } from "../../style/article"

const ReviewListContainer = styled(Article)`
  margin-top: 8rem !important;
  min-height: 60vh;
`

const ReviewIndexPage = () => {
  return (
    <Layout>
      <SEO title="Reviews | Get Fitness Fluent" />
      <ReviewListContainer>
        <h1>Latest Reviews</h1>
        <p>
          We are currently hard at work researching and trying out various
          equipment to make sure we give a fair and decent review. Check back
          regulary to find out when we have released the first batch of reviews
          and comparisons.
        </p>
      </ReviewListContainer>
    </Layout>
  )
}

export default ReviewIndexPage
