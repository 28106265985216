import styled, { css } from "styled-components"

export const Article = styled.article<{ headerImg?: string }>`
  margin: 4rem auto 8rem auto;
  // Targets only the first level childs
  & > * {
    width: 66%;
    margin-right: auto;
    margin-left: auto;
  }
  h1 {
    text-align: center;
    padding-bottom: 4rem;
    width: 90%;
    margin: auto;
    font-size: 3rem;
    -webkit-text-stroke: 1px black;
  }
  h3 {
    font-weight: 500;
    font-size: 1.3rem;
  }
  ol {
    margin: 1.4rem 0rem;
    ::marker {
      font-size: 3rem;
    }
    width: 66%;
    margin: auto;
  }
  ul {
    font-size: 0.8rem;
    padding-left: 20px;
    list-style: none;
    li {
      position: relative;
    }
    li::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 11px;
      left: -20px;
      border-radius: 50%;
      background-color: #008080;
    }
  }

  header {
    width: 100%;
    height: 24rem;
    margin-top: 4rem;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      color: white;
      padding-top: 10rem;
    }
  }
  section {
    padding-top: 2rem;
    width: 66%;
    margin: auto;
    h2 {
      margin-top: 2.2rem;
      margin-bottom: 1rem;
    }
    h3 {
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      font-weight: 400;
    }
    p {
      margin-bottom: 1rem;
    }
    a:visited {
      text-transform: none;
    }
    border-bottom: 2px solid #008080;
  }
  .split-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4rem auto;
    div {
      margin-top: 0;
    }
    img {
      height: auto;
      width: 40%;
      max-width: 50%;
      flex-grow: 2;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    }
  }
  div {
    width: 75%;
    margin: 4rem auto auto auto;
    p {
      margin-bottom: 2rem;
    }
  }
  .highlight,
  strong {
    font-weight: bolder;
    color: rgba(0, 128, 128, 1);
    font-style: italic;
    font-size: 1.1rem;
  }
  .intro,
  blockquote {
    color: #8c98a8;
    padding: 0 1rem;
  }
  blockquote {
    margin: 2rem auto;
  }
  .link {
    border-bottom: 4px solid rgba(0, 128, 128, 1);
  }
  img {
    height: auto;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  }
  .read-more {
    border-top: 2px solid #008080;
    border-bottom: 2px solid #008080;
    padding: 2rem 0;
    span {
      font-size: 0.9rem;
      font-weight: 300;
      vertical-align: middle;
      background-color: #e2e8f0;
      padding: 0.2rem 0.4rem;
    }
    h2 {
      text-align: center;
      color: #008080;
    }
    h3 {
      margin-top: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  .home {
    width: 4rem;
    margin: 2rem auto;
  }
  @media (max-width: 800px) {
    & > * {
      width: 80%;
      margin-right: auto;
      margin-left: auto;
    }

    header {
      margin-top: 0;
      h1 {
        font-size: 1.3rem;
        text-shadow: 1px 1px black;
      }
    }
    blockquote {
      margin: 0.6rem auto;
    }
    p {
      font-size: 0.8rem;
    }
    .split-section {
      flex-wrap: wrap;
      img {
        max-width: 100%;
      }
    }
    section,
    div {
      width: 90%;
      font-size: 0.8rem;
    }
  }
`
